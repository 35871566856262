<template>
    <TransitionRoot as="template" :show="isModalOpen">
        <Dialog class="relative z-20" @close="close">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-slate-800/60 transition-opacity" />
            </TransitionChild>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center lg:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden rounded-lg bg-frontend-header/95 px-4 w-full md:w-1/2 xl:w-1/3 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6">
                            <div>
                                <div class="flex flex-row items-center text-3xl font-semibold text-white mb-6">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 14 14" class="mx-auto">
                                        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                                            <path d="M7 5v3" />
                                            <circle cx="7" cy="11" r=".5" />
                                            <path d="M7.89 1.05a1 1 0 0 0-1.78 0l-5.5 11a1 1 0 0 0 .89 1.45h11a1 1 0 0 0 .89-1.45Z" />
                                        </g>
                                    </svg>
                                    <button @click="close" class="absolute top-0 right-0 mr-2 mt-2 hover:bg-red-900/60 rounded-full text-red-600 p-1.5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M256 512a256 256 0 1 0 0-512a256 256 0 1 0 0 512m-81-337c9.4-9.4 24.6-9.4 33.9 0l47 47l47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47l47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47l-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47l-47-47c-9.4-9.4-9.4-24.6 0-33.9" />
                                        </svg>
                                    </button>
                                </div>
                                <div class="w-full flex flex-col text-white mt-8 justify-center items-center gap-4">
                                    <p class="text-center">
                                        Warning! This part belongs to a model with a colour of <span class="font-semibold text-red-500">{{ color }}</span>. Please ensure you are purchasing a part with the colour you require.
                                    </p>
                                    <button @click="submitAddProduct" class="w-40 py-2 text-white mx-auto bg-frontend-brand hover:bg-frontend-brand/60">
                                        Add to Cart
                                    </button>
                                    <button @click="close" class="w-40 py-2 text-white mx-auto hover:underline">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {useCartStore} from '@/stores/CartStore';
import {computed} from 'vue';
import {updateCartItem} from '@/util/cart';

const cartStore = useCartStore();

const isModalOpen = computed(() => cartStore.addingProduct !== undefined);
const addingProduct = computed(() => cartStore.addingProduct);
const color = computed(() => cartStore.colorModalColor);

const close = () => {
    cartStore.addingProduct = undefined;
    cartStore.addingProductQuantity = 0;
    //wait for the modal to close before resetting the color
    setTimeout(() => {
        cartStore.colorModalColor = '';
    }, 250);
};

const submitAddProduct = () => {
    updateCartItem(addingProduct.value, cartStore.addingProductQuantity);
    close();
};

</script>

import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";
import {Manufacturer, defaultManufacturer} from "../util/manufacturers";

export const useThemeStore = defineStore('theme-store', () => {
    const currentManufacturer: Ref<Manufacturer> = ref(defaultManufacturer)
    const subdomain = ref('')

    const setManufacturer = (manufacturer: Manufacturer) => {
        currentManufacturer.value = manufacturer
    }

    const hasSubdomain = computed(() =>
        subdomain.value
        && subdomain.value !== ''
        && subdomain.value !== 'quadzilla'
        && subdomain.value !== 'quadzillaparts'
        && subdomain.value !== 'new'
        && subdomain.value !== 'www'
        && subdomain.value !== 'staging'
        && subdomain.value !== 'internal-quadzilla'
    );

    return {
        currentManufacturer,
        setManufacturer,
        subdomain,
        hasSubdomain
    }
}, {
    persist: {
        enabled: false
    },
})

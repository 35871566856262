import {defineStore} from "pinia";
import {computed, Ref, ref} from "vue";

export enum Currency {
    GBP = '£',
    EUR = '€'
}

export const usePreferenceStore = defineStore('preference-store', () => {
    const searchOpen = ref(false)

    const currency = ref<Currency>(Currency.GBP)
    const vatIncluded = ref(true)
    const isGbp = computed(() => currency.value === "GBP")
    return {
        currency,
        vatIncluded,
        isGbp,
        searchOpen,
    }
}, {
    persist: {
        enabled: true
    },
})

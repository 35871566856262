
export const manufacturerConfig: Manufacturer[] = [
    {
        name: 'Quadzilla',
        headline: 'Engineered To Exhilarate',
        hero: 'Quadzilla is the UK\'s largest supplier of on and off-road quads and buggies. Our award winning company has been the market leader for over 10 years.',
        subdomain: '',
        logo: '/images/quadzilla-logo.png',
        theme: {
            'frontend-brand': '194 41 23',
            'frontend-header': '22 28 43',
            'frontend-bg': '44 55 85',
        }
    },
    {
        name: 'CFMOTO',
        headline: 'The ATV & UTV Range',
        hero: 'CFMOTO is a ATV and Side x Side Manufacturer. We provide people with quality powersport vehicles such as our CFORCE ATVs & ZFORCE Side x Sides.',
        subdomain: 'cfmoto',
        logo: '/frontend/CFMOTO-logo.svg',
        theme: {
            'frontend-brand': '49 155 180',
            'frontend-header': '0 0 0',
            'frontend-bg': '0 0 0',
        }
    },
    {
        name: 'WK Bikes',
        headline: 'Great value, 50cc and 125cc motorcycles',
        hero: 'WK Bikes has been selling motorcycles and scooters in the UK for over 20 years and have thousands of happy and loyal customers. We only import the highest quality and most reliable motorcycles from our trusted factory partners.',
        subdomain: 'wkbikes',
        logo: '/frontend/WKBIKES-logo.png',
        theme: {
            'frontend-brand': '165 190 28',
            'frontend-header': '0 0 0',
            'frontend-bg': '0 0 0',
        },
    },
    {
        name: 'UM Motorcycles',
        headline: 'CONQUER THE WORLD WITH UM MOTORCYCLES',
        hero: 'At UM® Motorcycles, we design and develop unique bikes with a singular purpose: to provide our customers with the ultimate sense of freedom that only a motorcycle can offer. We are renowned for producing high-quality small to medium capacity motorcycles ranging from 125cc to 300cc, packed with outstanding features.',
        subdomain: 'ummotorcycles',
        logo: '/frontend/UMMOTORCYCLES-logo.png',
        theme: {
            'frontend-brand': '255 102 0',
            'frontend-header': '0 0 0',
            'frontend-bg': '0 0 0',
        },
    },
    {
        name: 'Slam Bikes',
        headline: 'HIGH PERFORMANCE, EPIC FUN',
        hero: 'Slam Bikes introduces the latest range of high performance bikes, with an incredible line-up of six distinctive models ranging for 70cc to 250cc. Our bikes are the complete package with a stylish design, powerful and reliable motors and unbeatable prices.',
        subdomain: 'slambikes',
        logo: '/frontend/SLAMBIKES-logo.png',
        theme: {
            'frontend-brand': '184 151 0',
            'frontend-header': '0 0 0',
            'frontend-bg': '0 0 0',
        },
    }
]
export const defaultManufacturer = manufacturerConfig[0]

export function getManufacturer(subdomain: string): Manufacturer {
    const dealer = manufacturerConfig.find(dealer => dealer.subdomain === subdomain)
    if (dealer) return dealer
    return manufacturerConfig[0]
}

export interface Manufacturer {
    name: string
    headline: string
    hero: string
    subdomain: string
    theme: {
        'frontend-brand': string
        'frontend-header': string
        'frontend-bg': string
    }
}

import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import Main from '@/main.js';
import VueApexCharts from 'vue3-apexcharts';
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';
import FrontendLayout from "@/Layouts/FrontendLayout.vue";
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Quadzilla';
const pinia = createPinia();
pinia.use(piniaPersist);

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: name => {
        const page = resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        );
        page.then((module) => {
            module.default.layout = name.startsWith('Frontend/') ? FrontendLayout : module.default.layout;
        });
        return page;
    },

    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueApexCharts)
            .use(pinia)
            .mount(el);
    },
    progress: {
        delay: 250,
        color: '#0ea5e9',
        includeCSS: true,
        showSpinner: true,
    },
});
export default class Application extends Main {
    constructor() {
        super();
    }
}

window.lineone = new Application();


import {useCartStore} from '@/stores/CartStore';
import {usePreferenceStore} from '@/stores/PreferenceStore';
import {useToastStore} from '@/stores/ToastStore';
import {Product} from '@/util/product';
import axios from 'axios';

export const getCart = async (): Promise<Cart> => {
    const cartStore = useCartStore();
    const preferencesStore = usePreferenceStore();

    const response = await fetch(route('cart.index', {token: cartStore.token, currency: preferencesStore.currency}));

    if (!response.ok) {
        throw new Error(`Failed to fetch cart: ${response.status}`);
    }

    return await response.json();
};

export const updateCartItem = async (product: Product, quantity: number, isDecreasingQuantity: boolean = false): Promise<Cart> => {
    const toastStore = useToastStore();
    const cartStore = useCartStore();
    const preferencesStore = usePreferenceStore();

    if (product.stock_level.message === 'Call Us') {
        toastStore.addToast('error', `Product ${product.name} is out of stock`);
        return;
    }

    if (product.is_coloured_part && cartStore.colorModalColor === '' && product.models[0].colour?.name) {
        cartStore.colorModalColor = product.models[0].colour.name;
        cartStore.addingProduct = product;
        cartStore.addingProductQuantity = quantity;
        return;
    }

    try {
        const response = await axios({
            method: 'post',
            url: route('cart.store'),
            data: {
                token: cartStore.token,
                product: product.uuid,
                quantity: quantity,
                currency: preferencesStore.currency,
                is_decreasing_quantity: isDecreasingQuantity,
            },
        });

        cartStore.token = response.data.token;

        // Only show toast when adding, not when adjusting quantity
        if (quantity === 0) {
            toastStore.addToast('success', `Removed all ${product.name} from your cart`);
        } else if (isDecreasingQuantity) {
            toastStore.addToast('success', `Removed a ${product.name} from your cart`);
        } else if (!isDecreasingQuantity) {
            toastStore.addToast('success', `Added ${product.name} to your cart`);
        }
        return response.data;
    } catch (error) {
        toastStore.addToast('error', `Failed to add ${product.name} to your cart`);
        throw error;
    }
};

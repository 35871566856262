import {Currency, usePreferenceStore} from "@/stores/PreferenceStore";
import {CartProduct} from "@/stores/CartStore";
import {Product} from "@/util/product";

export const getPrice = (product: Product) => {
    const preferences = usePreferenceStore()
    const isGbp = preferences.isGbp
    const isVatIncluded = preferences.vatIncluded
    if(isGbp) return isVatIncluded ? product.formatted_pricing.discounted_pricing.gbp_with_vat : product.formatted_pricing.discounted_pricing.gbp_without_vat
    return isVatIncluded ? product.formatted_pricing.discounted_pricing.eur_with_vat : product.formatted_pricing.discounted_pricing.eur_without_vat
}

export const getFriendlyPrice = (product: Product) => {
    const preferences = usePreferenceStore()
    const isGbp = preferences.isGbp
    const isVatIncluded = preferences.vatIncluded
    if(isGbp) return isVatIncluded ? product.formatted_pricing.gbp_friendly_with_vat : product.formatted_pricing.gbp_friendly_without_vat
    return isVatIncluded ? product.formatted_pricing.eur_friendly_with_vat : product.formatted_pricing.eur_friendly_without_vat
}

export const getFriendlyDiscountedPrice = (product: Product) => {
    const preferences = usePreferenceStore()
    const isGbp = preferences.isGbp
    const isVatIncluded = preferences.vatIncluded
    if(isGbp) return isVatIncluded ? product.formatted_pricing.discounted_pricing.gbp_friendly_with_vat : product.formatted_pricing.discounted_pricing.gbp_friendly_without_vat
    return isVatIncluded ? product.formatted_pricing.discounted_pricing.eur_friendly_with_vat : product.formatted_pricing.discounted_pricing.eur_friendly_without_vat
}

export const getFriendlyMultiplier = (product: Product, quantity: number) => {
    const preferences = usePreferenceStore()
    const isGbp = preferences.isGbp
    const isVatIncluded = preferences.vatIncluded

    const discounted = product.formatted_pricing.discounted_pricing.is_discounted

    const baseData = discounted
                     ? product.formatted_pricing.discounted_pricing
                     : product.formatted_pricing

    let unitPrice = 0
    if (isGbp && isVatIncluded) {
        unitPrice = baseData.gbp_with_vat
    } else if (isGbp && !isVatIncluded) {
        unitPrice = baseData.gbp_without_vat
    } else if (!isGbp && isVatIncluded) {
        unitPrice = baseData.eur_with_vat
    } else {
        unitPrice = baseData.eur_without_vat
    }

    const totalPrice = unitPrice * quantity
    const currencySymbol = isGbp ? '£' : '€'

    return currencySymbol + totalPrice
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

<template>
    <!-- Wrap everything in a parent div with ref -->
    <div ref="searchBarContainer" class="flex-col mx-auto z-40 w-full">
        <div class="flex items-center justify-center w-full mx-auto flex-row" :class="isDesktop? '' : 'px-4'">
            <form @submit.prevent="searchPage" class="flex w-full mx-auto flex-col">
                <input
                    v-model="searchText"
                    type="text"
                    class="w-full border-2 border-frontend-brand px-2 placeholder-white/70 focus:outline-none"
                    :class="isDesktop
                        ? 'border-white/50 bg-frontend-brand h-8 text-white focus:border-white border-r-0'
                        : 'h-12 text-frontend-brand'
                    "
                    placeholder="Enter Keyword, VIN, or Registration No."
                />

                <!-- The dropdown results -->
                <div v-if="searchOpen" class="flex absolute mt-[34px] mx-auto flex-col items-center">
                    <Link
                        v-for="result in searchResults"
                        :key="result.uuid"
                        :href="route('products.show', result.slug)"
                        @click="reset"
                        class="flex z-40 w-80 bg-white hover:bg-gray-100 text-black flex-row items-center min-h-12 gap-2 justify-start border-b border-frontend-brand/20 p-2"
                    >
                        <img
                            v-if="result.photo_url"
                            :src="result.photo_url"
                            alt="Vehicle Image"
                            class="h-10 w-10 flex-shrink-0 rounded-md"
                        />
                        <div class="flex-1 min-w-0">
                            <p class="truncate">{{ result.name }}</p>
                            <p v-if="result.sku" class="text-sm text-gray-600 truncate">{{ result.sku }}</p>
                        </div>
                    </Link>

                    <div
                        v-if="loading"
                        class="flex z-40 w-80 bg-white hover:bg-gray-100 text-black flex-row items-center h-12 gap-2 justify-start border-b border-frontend-brand/20 p-2"
                    >
                        Loading...
                    </div>

                    <div
                        v-if="searchResults.length === 0 && !loading"
                        class="flex z-40 w-80 bg-white hover:bg-gray-100 text-black flex-row items-center h-12 gap-2 justify-start border-b border-frontend-brand/20 p-2"
                    >
                        No Results Found
                    </div>
                </div>
            </form>

            <button
                @click="searchPage"
                class="px-4"
                :class="isDesktop ? 'h-8 bg-white text-frontend-brand' : 'h-12 bg-frontend-brand text-white'"
            >
                Search
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { usePreferenceStore } from '@/stores/PreferenceStore'
import { Link } from '@inertiajs/vue3';
import { useFetch } from '@vueuse/core';
import { useToastStore } from '@/stores/ToastStore';

defineProps({
    isDesktop: {
        type: Boolean,
        default: false
    }
})

const preferenceStore = usePreferenceStore()
const toasts = useToastStore()

const searchBarContainer = ref<HTMLDivElement | null>(null)

const searchOpen = computed(() => preferenceStore.searchOpen)
const loading = ref(false)
const searchResults = ref<any[]>([])
const searchText = ref('')

onMounted(() => {
    document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside)
})

function handleClickOutside(e: MouseEvent) {
    if (!searchBarContainer.value) return
    if (!searchBarContainer.value.contains(e.target as Node)) {
        preferenceStore.searchOpen = false
    }
}

// Methods
const reset = () => {
    searchText.value = ''
    preferenceStore.searchOpen = false
}

function searchPage() {
    if (searchText.value?.length >= 3) {
        preferenceStore.searchOpen = false
        window.location.href = `/products?search=${encodeURIComponent(searchText.value)}`
    } else {
        toasts.addToast('error', 'Search term must be at least 3 characters long')
    }
}

async function search() {
    loading.value = true
    const { data } = await useFetch(`/api/search?search=${searchText.value}`).get().json()
    if (data.value) {
        searchResults.value = data.value.searchResults
    }
    loading.value = false
}

watch(searchText, async (val) => {
    if (!val) searchResults.value = []

    if (val && val.length >= 3) {
        preferenceStore.searchOpen = true
        await search()
    } else {
        preferenceStore.searchOpen = false
    }
})
</script>
